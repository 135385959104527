import { useEffect, useRef, useState } from "react";
import { playSound } from "../../service/play-sound.service";
import { listenEventMotion, removeEventMotion } from "../../service/device-motion.service";
import mute from '../../assets/animations/mute-unmute.json';
import Lottie from "lottie-react";

function ShakeChrome() {
  const [isPlay, setIsPlay] = useState(false);
  const [canPlay, setCanPlay] = useState(true);
  const [acceleration, setAcceleration] = useState({ x: 0, y: 0, z: 0 });
  const [previousAcceleration, setPreviousAcceleration] = useState({ x: 0, y: 0, z: 0 });

  const animationRef = useRef<any>(null);


  useEffect(() => {
    const handleDeviceMotion = (event: any) => {
    if (event == null) return;
    const { x, y, z } = event.accelerationIncludingGravity;

    setPreviousAcceleration(acceleration);
    setAcceleration({ x, y, z });
  };

    animationRef.current.playSegments([0, 50], true);
    listenEventMotion(handleDeviceMotion);

    return () => {
      removeEventMotion(handleDeviceMotion);
    };
  }, [acceleration]);


  useEffect(() => {
    const threshold = 20; // Adjust the threshold value as needed
    const magnitude = Math.sqrt(
      acceleration.x ** 2 + acceleration.y ** 2 + acceleration.z ** 2
    );
    const checkMotionPattern = (magnitude: number) => {
    // Implement the logic to check if the motion resembles a spoon hitting a pan
    const { x } = acceleration;
    const { x: prevX, y: prevY, z: prevZ } = previousAcceleration;

    // Calculate the change in acceleration along the X-axis
    const deltaX = Math.abs(x - prevX);
    // Calculate the change in acceleration magnitude
    const deltaMagnitude = Math.abs(magnitude - Math.sqrt(prevX ** 2 + prevY ** 2 + prevZ ** 2));

    // Example conditions: differentiate between fast and slow motions based on the change in acceleration
    const isFastMotion = deltaX > 10 && deltaMagnitude > 15;
    const isSlowMotion = deltaX > 5 && deltaMagnitude <= 15;

    return isFastMotion || isSlowMotion;
  };
    const isMotionLikeSpoonHittingPan = checkMotionPattern(magnitude); // Check if the motion resembles a spoon hitting a pan

    if (canPlay && magnitude > threshold && isPlay && isMotionLikeSpoonHittingPan) {
      setCanPlay(false);
      playSound();
      setTimeout(() => {
        setCanPlay(true);
      }, 100);
    }
  }, [acceleration, canPlay, isPlay, previousAcceleration]);

  const toggleMute = () => {
    setIsPlay(!isPlay);
    if (!isPlay) animationRef.current.playSegments([50, 90], true);
    else animationRef.current.playSegments([0, 50], true);
  };

  return (
    <>
      <div onClick={toggleMute}>
        <Lottie
          animationData={mute}
          autoplay={false}
          loop={false}
          style={{ height: "40px" }}
          lottieRef={animationRef}
        />
      </div>
    </>
  );
}

export default ShakeChrome;
