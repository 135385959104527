import { useEffect, useRef, useState } from "react";
import { playSound } from "../../service/play-sound.service";
import mute from '../../assets/animations/mute-unmute.json';
import askPermissionIcon from '../../assets/screen-lock-ask-permission.svg';
import Lottie from "lottie-react";

function ShakeIos(){
    const [isPlay, setIsPlay] = useState(false);
    const [canPlay, setCanPlay] = useState(true);
    const [isAllowed, setIsAllowed] = useState(false)

    const animationRef = useRef<any>(null);

    const askPermission = () => {
        (DeviceMotionEvent as any).requestPermission().then( (response : any)  => {
            if (response === 'granted') {
                setIsAllowed(true);
                toggleMute();
                window.addEventListener('devicemotion', handleDeviceMotion);
              }
            })
            .catch(console.error);
    }

    const [acceleration, setAcceleration] = useState({ x: 0, y: 0, z: 0 });

    const handleDeviceMotion = (event: any) => {
        if(event == null) return;
        const { x, y, z } = event.accelerationIncludingGravity;
        setAcceleration({ x, y, z })
    };

    useEffect(() => {
        const threshold = 20; // set the threshold for triggering the sound effect
        const magnitude = Math.sqrt(acceleration.x ** 2 + acceleration.y ** 2 + acceleration.z ** 2);
        if (canPlay && magnitude > threshold && isPlay) {
            setCanPlay(false);
            playSound();
            setTimeout(() => {
                setCanPlay(true);
            }, 100);
        }
    }, [acceleration, canPlay, isPlay])

    const toggleMute = () => {
        setIsPlay(!isPlay);
        if(isPlay)  animationRef.current.playSegments([0, 50], true);
        else animationRef.current.playSegments([50, 90], true);
    }

    return (
        <>
            <div className={ `pointer ${!isAllowed ? '' : 'invisible' } `} >
                <img src={askPermissionIcon} onClick={askPermission} alt="MACRON EXPLOSON" />
            </div>
            <div className={ `pointer ${isAllowed ? '' : 'invisible' } `}>
                <div onClick={toggleMute} >
                    <Lottie 
                        animationData={mute} 
                        autoplay={false} 
                        loop={false} 
                        style={{height:"40px"}}
                        lottieRef={animationRef}
                        />
                </div>
            </div>

        </>
    )
}

export default ShakeIos