export const listenEventMotion = (handleDeviceMotion: any) => {
    window.addEventListener('devicemotion', handleDeviceMotion);
    return () => {
        window.removeEventListener('devicemotion', handleDeviceMotion);
    };
};

export const removeEventMotion = (handleDeviceMotion: any) => {
    window.removeEventListener('devicemotion', handleDeviceMotion);
    return () => {
        window.removeEventListener('devicemotion', handleDeviceMotion);
    };
};