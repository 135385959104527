import './App.css'
import ShakeChrome from './components/shake-chrome/ShakeChrome'
import ShakeIos from './components/shake-ios/ShakeIos';
import shake from './assets/animations/shake.json';
import m14i from './assets/m14i-v2.png';
import qrCode from './assets/qr-code.png';
import Lottie from "lottie-react";
import { playSound } from './service/play-sound.service';



function App() {

  const userAgent = window.navigator.userAgent;

  const isSafari = /Safari/.test(userAgent) && /Apple Computer/.test(navigator.vendor);

  const redirectToM14i = () => {
    window.location.href = "https://m14i.com";
  }

 return (
    <>
  { (window.innerWidth <= 768) ? 
      <div className='app-container' onClick={playSound}>
        <div className='title-container'>
          <h1>Exploson</h1>
          <div className="shake-container">
            {(isSafari) ? <ShakeIos></ShakeIos> : <ShakeChrome></ShakeChrome>}
          </div>
          </div><div className='lottie-container'>
            <Lottie
              animationData={shake}
              loop={true}
              style={{ height: "100px", width: "100px" }} />
          </div>
        <div className='footer-container'>
          <span className='fotter-content'>
            Powered by <img onClick={redirectToM14i} src={m14i} width={20} alt="MACRON EXPLOSON" />
          </span>
        </div>
      </div>
      : 
          <>
          <div className='app-container-desktop' onClick={playSound}>
            <h1>Exploson</h1>
            <div className='link-container'>
              <a href="https://twitter.com/Explosson?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-size="large" data-lang="en" data-show-count="false">Follow @Explosson</a><script async src="https://platform.twitter.com/widgets.js"></script>         
              <div className="informations-container">Site optimisé pour les téléphones mobiles</div>
              <img src={qrCode} width={200}  alt="MACRON EXPLOSON"/>
            </div>
            
            <div className='footer-container'>
              <span className='fotter-content'>
              Powered by <img onClick={redirectToM14i} src={m14i} width={20} alt="MACRON EXPLOSON" />
              </span>
            </div>
          </div>
          </>
      }
    </>
  )
}

export default App
