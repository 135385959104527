import panMetal from '../assets/sounds/pan_metal.mp3'
import panMetal2 from '../assets/sounds/pan_metal2.mp3'
import slam2 from '../assets/sounds/slam-pan.mp3'

const soundFiles = [
    panMetal,
    panMetal,
    panMetal,
    panMetal2,
    panMetal2,
    panMetal2,
    panMetal,
    panMetal,
    panMetal,
    panMetal,
    slam2,
    slam2,
];

export const playSound = () => {
    let sound = new Audio(soundFiles[getRandomSoundIndex()]);
    sound.play();
}


// Function to generate normally distributed random numbers using Box-Muller transform
export const generateRandomNormal = (mean: number, stdDev: number) => {
    var u1 = Math.random();
    var u2 = Math.random();
  
    var z0 = Math.sqrt(-2.0 * Math.log(u1)) * Math.cos(2 * Math.PI * u2);
    return z0 * stdDev + mean;
  }
  
const getRandomSoundIndex = () => {
    var mean = (soundFiles.length - 1) / 2; // Mean index
    var stdDev = (soundFiles.length - 1) / 6; // Standard deviation

    var randomIndex = Math.floor(generateRandomNormal(mean, stdDev));
    return Math.max(0, Math.min(randomIndex, soundFiles.length - 1));
}